import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { API_URL } from '../constants/index';

const Details = ({ screenplayId, progress }) => {
  const [categories, setCategories] = useState([]);
  const [insights, setInsights] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = await getAccessTokenSilently();
        const insightsResponse = await axios.get(`${API_URL}/screenplay/${screenplayId}/insights/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const insights = insightsResponse.data;

        const insightCategories = insights.map((insight) => insight.category);

        const combinedCategories = [...new Set([...insightCategories])];

        setCategories(combinedCategories);
        setInsights(insights);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, [screenplayId, getAccessTokenSilently, progress]);

  const findInsightsByCategory = (category) => insights.filter((insight) => insight.category === category);

  return (
    <Tabs className="tabs" selectedTabClassName="tabs-selected">
      <TabList>
        {categories.map((category, index) => (
          <Tab key={index} style={{ fontSize: '20px' }}>
            {category}
          </Tab>
        ))}
      </TabList>
      <div className="details-container">
        {categories.map((category, index) => {
          const categoryInsights = findInsightsByCategory(category);

          return (
            <TabPanel key={index}>
              <div className="category-insight">
                {categoryInsights.length > 0 ? (
                  categoryInsights.map((insight, insightIndex) => (
                    <ReactMarkdown key={insightIndex} children={insight.insight} remarkPlugins={[remarkGfm]} />
                  ))
                ) : (
                  'No Insights Data'
                )}
              </div>
            </TabPanel>
          );
        })}
      </div>
    </Tabs>
  );
};

export default Details;
